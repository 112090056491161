import * as React from 'react';
import Mint from '../components/Mint/Mint';
import Layout from '../components/layout';

import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'


const MintPage = () => {
  return (
    <>
    <Layout>
      <Mint />
    </Layout> 
    <ToastContainer
      hideProgressBar
      position="bottom-right"
      autoClose={2000}
    />
    </>
  );
};

export default MintPage;


