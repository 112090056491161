import { motion } from 'framer-motion';
import React , {useEffect} from 'react';
import { paragraphAnimation } from '../../utils/config';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';
import axios from 'axios';
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import DiscordButton from '../lib/DiscordButton/DiscordButton';
import logoImg from '../../assets/images/alpha_logo.png';
import txtImg from '../../assets/images/sacred_shard.png';

import decrementIcon from '../../assets/icons/up.svg';
import incrementIcon from '../../assets/icons/down.svg';



import { useSelector } from 'react-redux';
import './Mint.scss';
import {mintEnabled, setweb3provider} from '../../action/CounterAction';
import reduxStore from '../../ReduxStore';
import { toast } from 'react-toastify';
import WalletConnectProvider from '@walletconnect/web3-provider'
import Header from '../Header/Header'

const abi = require('../../helper/abi.json')
const minting_abi = require('../../helper/mint.json')

let web3Modal;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
  },
}

if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required  
  })

  console.log(web3Modal)
}


export default function Mint() {

  //const base_uri = "https://api.supremekong.com";
    const base_uri = "http://localhost:8001";

  const web3provider = useSelector(state => state.web3Provider);
  const address = useSelector(state => state.address);
  const [mintVisible, setMintVisible] = React.useState(true);
  const [amount, setAmount] = React.useState(0);
  const [supply, setSupply] = React.useState(0);
  // const countDownDate = new Date(process.env.GATSBY_MINT_OPEN).getTime();
  const [showModal, setShowModal] = React.useState(false);

  // const [countDown, setCountDown] = React.useState(
  //   countDownDate - new Date().getTime()
  // ); 

  const [maxMinted, setMaxMinted] = React.useState(1)
  const [maxSupply, setMaxSupply] = React.useState(0);
  const [percentage, setPercentage] = React.useState(0)

  let contract_address = process.env.GATSBY_CONTRACT_ADDRESS
  let minting_address = process.env.GATSBY_MINTING_ADDRESS
  let mint_price = process.env.GATSBY_MINT_PRICE
  let maxSupplyData = process.env.GATSBY_SUPPLY

  useEffect(async() => {
    //Runs only on the first render
    if(web3provider){
      await loadMintType();
    }else{
      setMaxSupply(maxSupplyData)
      setSupply(0)
    }
  }, [web3provider]);

  const loadSupply = async() => {
    const signer = web3provider?.getSigner();
    const contract = new ethers.Contract(contract_address, abi, signer);
    const totalSupply = await contract.totalSupply()

    const shardContract = new ethers.Contract(minting_address, minting_abi, signer);
    const currentNumber = await shardContract.current();
    const percent = (currentNumber/maxSupply).toFixed(2) * 100;
    setPercentage(percent)
    setSupply(currentNumber.toNumber());
  }


  const loadMintType = async() => {
    const signer = web3provider?.getSigner();
    setMaxSupply(maxSupplyData);
    setAmount(1)
    await loadSupply();
  }

  const increment = () =>{
    // if(amount < maxMinted ){
    //   setAmount(amount+1);
    // }
  }

  const max = () =>{
    setAmount(maxMinted)
  }

  const decrement = () =>{
    // if(amount > 1){
    //   setAmount(amount-1);
    // }
  }

  const mint = async() => {
    whitelistMint()
  }

  const claim = async() => {
    publicClaim()
  }

  const connect = async () => {
    if (web3Modal) {
      try {
        const provider = await web3Modal.connect()
        console.log("provider",provider)
        const web3Provider = new ethers.providers.Web3Provider(provider)
        const signer = web3Provider.getSigner()
        const address = await signer.getAddress()
        const network = await web3Provider.getNetwork()
        reduxStore.dispatch(setweb3provider(provider,web3Provider,address,network))
        toast.success('Connected to Web3')
      } catch (e) {
        console.log('connect error', e)
      }
    } else {
      console.error('No Web3Modal')
    }
  }

  const publicMint = async () => {
    if (web3provider) {
      const signer = web3provider?.getSigner();
      const contract = new ethers.Contract(contract_address, abi, signer);
      let price = ethers.utils.parseEther(mint_price);
      const id = toast.loading("Minting In Progress");
      price = price.mul(amount);
      try {
        let overrides = {
          value: price
        };
  
        const tx = await contract.mint(amount, overrides);
        let receipt = await tx.wait();
        toast.update(id, { render: "Minting Success", type: "success", isLoading: false, autoClose: 3000 });
        await loadMintType();
        toast.dismiss(id); // Close the loading toast after minting completes
      } catch (err) {
        console.log(err);
        toast.update(id, { render: "Minting Failed", type: "error", isLoading: false, autoClose: 1000 });
      }
    } else {
      toast.error("Please Connect Your Wallet using Metamask");
    }
  };

  const whitelistMint = async () => {
    if (web3provider) {
      const signer = web3provider?.getSigner();
      const contract = new ethers.Contract(minting_address, minting_abi, signer);
      let price = ethers.utils.parseEther(mint_price);
      const id = toast.loading("Minting In Progress");
      price = price.mul(amount);
      try {
        let overrides = {
          value: price
        };
        let now = Math.floor(Date.now() / 1000)
        const body = {
            address: address,
            amount: 1,
            timestamp: now
        }

        const resp = await axios.post(base_uri+'/signWhitelist',
        body,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
        }
        })
  
        const tx = await contract.buyAsset(amount, resp.data.nonce,resp.data.hash,resp.data.signature, overrides);
        let receipt = await tx.wait();
        toast.update(id, { render: "Minting Success", type: "success", isLoading: false, autoClose: 3000 });
        await loadMintType();
        toast.dismiss(id); // Close the loading toast after minting completes
      } catch (err) {
        console.log(err);
        toast.update(id, { render: "Minting Failed", type: "error", isLoading: false, autoClose: 1000 });
      }
    } else {
      toast.error("Please Connect Your Wallet using Metamask");
    }
  };

  const publicClaim = async () => {
    if (web3provider) {
      const signer = web3provider?.getSigner();
      const contract = new ethers.Contract(contract_address, abi, signer);
      const id = toast.loading("Claiming In Progress");
      try {
  
        const tx = await contract.claim();
        let receipt = await tx.wait();
        toast.update(id, { render: "Claiming Success", type: "success", isLoading: false, autoClose: 3000 });
        await loadMintType();
        toast.dismiss(id); // Close the loading toast after minting completes
      } catch (err) {
        console.log(err);
        toast.update(id, { render: "Claiming Failed", type: "error", isLoading: false, autoClose: 1000 });
      }
    } else {
      toast.error("Please Connect Your Wallet using Metamask");
    }
  };

  return (
    <>
    <header>
      <div className="logo-container">
        <img id="logo" src={logoImg} alt="Your logo" />
      </div>
      <div className="connect-button-container">
        { !web3provider &&
          <button id="connect-button" onClick={connect}>CONNECT WALLET</button>
        }
      </div>
    </header>
    <motion.section 
    {...paragraphAnimation}
    id='Mint' className='Mint'>
      { mintVisible &&
      <div className='Mint__content'>
        <div className='flex-container'>
          <div className='flex-item-left' style={{color:'white'}}>
            {/* { !web3provider &&  
              <div className='mintBtn' onClick={connect}>
                CONNECT Wallet
              </div>
            } */}
            {/* <div className='address' style={{marginBottom:"10px",color:'white'}}>
              { address &&
                  <p style={{fontSize:"32px",fontWeight:"800"}}>{ address.substring(0,3)}...{address.substring(address.length-4,address.length) }</p>
              }
            </div> */}
            <div className='sacred-txt'>
              <img src={txtImg} ></img>
            </div>

            <div className="progress-bar-container">
              <div className="progress-bar-labels">
                <span className="progress-bar-text-left">100% Minted</span>
                <span className="progress-bar-text-right">2000/2000</span>
              </div>
              <div className="progress-bar">
                <div className="progress-bar-fill" style={{width: `${100}%`}}></div>
              </div>
            </div>

            <div className="counter-container">
              <div className='top-flex'>
                  <span>MINTED OUT</span>
                  {/* <span>FREE CLAIM</span> */}
              </div>

              {/* <div className='bottom-flex'>
                  <div id="number-counter" className="counter-button">
                    <span>{amount}</span>
                  </div>

                  <div id="number-counter" className="inc-button" onClick={decrement}>
                    <img src={incrementIcon} width={15} height={15}></img>
                  </div>

                  <div id="number-counter" className="inc-button" onClick={increment}>
                    <img src={decrementIcon} width={15} height={15}></img>
                  </div>

                  <div id="number-counter" className="max-button" onClick={max}>
                    <span>MAX</span>
                  </div>
              </div> */}

              
            </div>

            <div >
              {/* <button className='mintBtn' onClick={mint}>MINT</button> */}
              <a href='https://opensea.io/collection/sacred-shard' target='_blank'><button className='mintBtn' >OPEN SEA</button></a>
            </div>
            
            {/* <div className='address' style={{marginBottom:"10px",color:'white'}}>
              <p style={{fontSize:"44px",fontWeight:"800"}}>{supply} / {maxSupply} Minted</p>
            </div>

            <div className='address'>
              <p style={{fontSize:"18px",fontWeight:"800",color:'white'}}>Amount</p>
            </div>

            <div className='amountBtn'>
              <div className='decrement' onClick={decrement}>
              </div>

              <div className='amountTxt'>
                {amount}
              </div>

              <div className='increment' onClick={increment}>
              </div>
            </div> */}


          </div>

          <div className='flex-item-right'>
          </div>
        </div>
      </div>
      }
    </motion.section>
    { showModal &&
      <div className='modal-overlay'>
            <div className='modal-title'>
              <a href='https://opensea.io/collection/COLLECTION_NAME'>
                <DiscordButton onClick={ ()=>{}} href='' text='BUY ON OPENSEA' blank />
              </a>
            </div>
      </div>
    }
    </>
  );
}
